import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

export default function (props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: { main: "#D3DDE6" },
          secondary: { main: "#18A4E0" }
        }
      }),
    [prefersDarkMode]
  );

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
