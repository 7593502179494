import React from "react";

import {
  TextField,
  Card,
  CardContent,
  CardActions,
  Button,
  Icon,
  CircularProgress
} from "@material-ui/core";

class LeadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sumbitted: false,
      name: "",
      email: "",
      message: ""
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }
  handleFormChange({ target }) {
    this.setState({ [target.name]: target.value.trim() });
  }
  handleFormSubmit() {
    let _this = this;
    if (!_this.state.loading) {
      _this.setState({ loading: true });
      setTimeout(() => {
        _this.setState({ loading: false, submitted: true });
      }, 2000);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Fab
          variant="extended"
          color="secondary"
          style={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            zIndex: 10,
            transform: "translateX(100% + '1em')"
          }}
          onClick={() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth"
            });
          }}
        >
          <Icon style={{ marginRight: "0.5rem" }}>mail</Icon>
          Contact
        </Fab>
        <h2>Let's get in touch</h2>
        <Card className="card">
          {!this.state.sumbitted && (
            <React.Fragment>
              <CardContent>
                <form>
                  <div>
                    <TextField
                      name="name"
                      className="contact-us--text-field"
                      label="Name"
                      fullWidth
                      helperText="How do I address you?"
                      required
                      disabled={this.state.loading}
                      value={this.state.name}
                      onChange={this.handleFormChange}
                    />
                    <TextField
                      name="email"
                      className="contact-us--text-field"
                      label="E-mail"
                      fullWidth
                      helperText="Where can I contact you at?"
                      required
                      disabled={this.state.loading}
                      value={this.state.email}
                      onChange={this.handleFormChange}
                    />
                    <TextField
                      name="message"
                      className="contact-us--text-field"
                      label="Message"
                      multiline
                      fullWidth
                      rows={4}
                      required
                      helperText="Your feedback, ideas or concerns"
                      disabled={this.state.loading}
                      value={this.state.message}
                      onChange={this.handleFormChange}
                    />
                  </div>
                </form>
              </CardContent>

              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<Icon>send</Icon>}
                  disabled={this.state.loading}
                  onClick={this.handleFormSubmit}
                >
                  Submit
                </Button>
                {this.state.loading && <CircularProgress size={24} />}
              </CardActions>
            </React.Fragment>
          )}
          {this.state.sumbitted && (
            <CardContent>
              {/* <lottie-player
                src="https://assets2.lottiefiles.com/datafiles/uoZvuyyqr04CpMr/data.json"
                background="transparent"
                speed="1"
                style={{ width: 75, height: 75 }}
                hover
                autoplay
              ></lottie-player> */}
              <h3>
                Hang on, I will get back to you at the soonest time possible.
              </h3>
            </CardContent>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default LeadForm;
