import React from "react";

import { Icon, Avatar, Fab, Typography } from "@material-ui/core";

import Work from "./components/Work";
import LeadForm from "./components/LeadForm";
// import Social from "./components/Social";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ["code", "design", "tinker"],
    };
  }
  componentDidMount(){
    let _this = this;
    fetch('/api/getWork')
    .then(response => response.json())
    .then(({works})=>{
      _this.setState({works})
    })
  }
  render() {
    return (
      <div className="App">
        <header>
          <div>
            <Avatar
              alt="Jordan Lim"
              src="https://firebasestorage.googleapis.com/v0/b/jordanlys-3def6.appspot.com/o/assets%2F2020-09-26%2001.17.56.jpg?alt=media&token=c48d15a8-3ef2-469c-bd44-a69bd1357268"
              style={{ height: "8rem", width: "8rem", margin: "1rem auto" }}
            />
            <Typography variant="h5" component="h1">
              Hello, I am Jordan.
            </Typography>
            <Typography variant="body1" component="p">
              I {this.state.title.join(", ")}.
            </Typography>
            <Typography variant="h4" component="p">
              <span role="img" aria-label="Singapore Flag">
                &#x1F1F8;&#x1F1EC;
              </span>
            </Typography>
          </div>
          <div className="backdrop-text"></div>
        </header>
        <section className="portfolio--section">
          <Typography gutterBottom variant="h4" component="h2">
            Recent Works
          </Typography>
          {this.state.works && this.state.works.map(({ title, blurb, img, url }, index) => (
            <Work
              key={index}
              img={img}
              title={title}
              blurb={blurb}
              url={url}
            />
          ))}
        </section>
        <footer>
          
          {/* <LeadForm className="lead" /> */}
          {/* <Social /> */}
        </footer>
      </div>
    );
  }
}

export default App;
