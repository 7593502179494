import React from "react";

import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Icon
} from "@material-ui/core";

function WorkContent({img, title, blurb}) {
  return (
    <React.Fragment>
      {img && (
        <CardMedia
          className="works--media"
          image={img}
          style={{ height: "250px" }}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {blurb}
        </Typography>
      </CardContent>
    </React.Fragment>
  );
}

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card style={{ fontFamily: "'Inter', Sans-serif", marginBottom: "1rem" }}>
        {this.props.url && <CardActionArea href={this.props.url} target="_blank">
          <WorkContent img={this.props.img} title={this.props.title} blurb={this.props.blurb}></WorkContent>
        </CardActionArea>}
        {!this.props.url && <WorkContent  image={this.props.image} title={this.props.title} blurb={this.props.blurb}></WorkContent>}
        {/* <CardActions>
          <IconButton aria-label="share">
            <Icon>share</Icon>
          </IconButton>
        </CardActions> */}
      </Card>
    );
  }
}

export default Work;
